import React, { useState, useRef } from 'react';


const ImageUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageLink, setImageLink] = useState('');
  const [dragOver, setDragOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);
  // setUploadedImage(imageUrl);

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID 47b2305c3bb6380');
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
        console.log(response);
        setIsLoading(false);
        setImageLink(response.data.link); // Assuming the server responds with the image link
        setSelectedFile('');
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  };

  const ImageUploaded = () => {
    const handleCopyLink = () => {
      if (imageLink) {
        navigator.clipboard
          .writeText(imageLink)
          .then(() => {
            alert('Link copied to clipboard!');
          })
          .catch((error) => {
            console.error('Failed to copy link:', error);
          });
      }
    };

    return (
      <div className="my-1 p-4 rounded-md bg-green-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              className="w-5 h-5 text-green-400"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium leading-5 text-green-800">
              Success
            </h3>
            <div className="mt-2 text-sm leading-5 text-green-700">
              <p>
                <a href={imageLink}>{imageLink}</a>
              </p>
            </div>
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <div>
                  <button
                    className="ml-3 px-2 py-1.5 rounded-md text-sm leading-5 font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150"
                    onClick={handleCopyLink}
                  >
                    Copy link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // Generate a preview URL
    const imageUrl = URL.createObjectURL(file);
    setPreviewImage(imageUrl);
  };

  const handleDeleteImage = () => {
    setSelectedFile('');
    setPreviewImage(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
    setDragOver(false);
  };

  const calculateFileSize = (file) => {
    const fileSizeInBytes = file.size;
    const fileSizeInKB = fileSizeInBytes / 1024; // 1 KB = 1024 bytes
    const fileSizeInMB = fileSizeInKB / 1024; // 1 MB = 1024 KB

    if (fileSizeInMB >= 1) {
      return fileSizeInMB.toFixed(2) + ' MB';
    } else {
      return fileSizeInKB.toFixed(2) + ' KB';
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      setIsLoading(true);
      setPreviewImage(null);
      let cb = uploadImageCallBack(selectedFile);
      console.log(cb);
      /*
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Replace the following with your actual upload logic
      // For example, you can use the Fetch API or Axios to send the file to a server
      fetch('your-upload-endpoint', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
        
        })
        .catch((error) => {
          console.error('Error uploading the file:', error);
        });
*/
    }
  };

  return (
    <div className="max-w-4xl mx-auto my-12 p-8 bg-white shadow-lg rounded-lg">
      <h1 className="text-4xl font-bold text-center mb-4">
        Imgur Image Uploader
      </h1>
      <p className="text-xl text-center mb-8">
        Upload your image below and directly receive a link for it.
      </p>
      <div
        className={`flex flex-col items-center p-10 border-2 border-dashed border-gray-300 rounded-lg  ${
          dragOver ? 'drag-over' : ''
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {!selectedFile && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-gray-500 mb-4 h-12 w-12"
          >
            <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" />
            <path d="M12 12v9" />
            <path d="m16 16-4-4-4 4" />
          </svg>
        )}

        <div>
          {!selectedFile && (
            <p className="mt-1 text-sm text-gray-600">
              <label className="font-medium text-indigo-600 transition duration-150 ease-in-out cursor-pointer hover:text-indigo-500 focus:outline-none focus:underline">
                Upload a file
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="fixed hidden"
                  ref={fileInputRef}
                />
              </label>{' '}
              or drag and drop
            </p>
          )}

          {previewImage && (
            <>
              <h1 className="mb-2 text-3xl my-3 text-center">
                Ready to upload
              </h1>
              <img
                src={previewImage}
                alt="Preview"
                className="border-indigo-200 border-dashed border-3 max-h-64 my-2 text-center"
              />
            </>
          )}

          {selectedFile && (
            <>
              <div className="px-0 py-3 text-center sm:px-6">
                <span className="flex items-center rounded-md shadow-sm">
                  <button
                    className={`w-fit justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 min-w-full ${
                      isLoading
                        ? 'text-gray-500 cursor-not-allowed'
                        : 'text-white-800'
                    } ${
                      isLoading
                        ? 'bg-gray-100'
                        : 'hover:bg-blue-100 focus:bg-blue-100'
                    } focus:outline-none transition ease-in-out duration-150`}
                    onClick={() => !isLoading && handleUpload()}
                    disabled={!selectedFile}
                  >
                    {isLoading ? 'Uploading...' : 'Upload'}
                  </button>
                </span>
              </div>
            </>
          )}
        </div>

        {!selectedFile && (
          <p className="text-gray-500">PNG, JPG, GIF up to 10MB</p>
        )}

        <div className="mt-2 text-sm leading-5 text-gray-500 inline-flex flex-wrap items-center">
          {selectedFile ? (
            <>
              <>
                {selectedFile ? (
                  <>
                    <svg
                      viewBox="0 0 512 512"
                      className="w-4 h-4 mr-1 text-gray-700 fill-current"
                    >
                      <path d="M416,64H96a64.07,64.07,0,0,0-64,64V384a64.07,64.07,0,0,0,64,64H416a64.07,64.07,0,0,0,64-64V128A64.07,64.07,0,0,0,416,64Zm-80,64a48,48,0,1,1-48,48A48.05,48.05,0,0,1,336,128ZM96,416a32,32,0,0,1-32-32V316.37l94.84-84.3a48.06,48.06,0,0,1,65.8,1.9l64.95,64.81L172.37,416Zm352-32a32,32,0,0,1-32,32H217.63L339.05,294.58a47.72,47.72,0,0,1,61.64-.16L448,333.84Z"></path>
                    </svg>
                    {selectedFile.name}
                    <span className="ml-2">
                      ( {calculateFileSize(selectedFile)})
                    </span>
                  </>
                ) : (
                  'No file selected'
                )}
              </>
              <button
                className="ml-3 rounded hover:bg-gray-200"
                onClick={handleDeleteImage}
              >
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  className="w-4 h-4"
                >
                  <path
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </button>{' '}
            </>
          ) : (
            <p>No image selected</p>
          )}
        </div>
      </div>
      {imageLink && <ImageUploaded />}
    </div>
  );
};

//

export default function App() {
  return (
    <div>
      <ImageUploader />
    </div>
  );
}
